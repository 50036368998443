<template>
  <div id="favor">
    <tmpl_header />

    <div class="container">
      <sidebar act="favor" />

      <div class="main">
        <div class="operates">
          <label :class="{ checked: selectedAll }" @click="checkAll"
            ><span>全选</span></label
          >
          <a class="btn" @click="cancelFavor">取消收藏</a>
        </div>

        <div class="card">
          <ul class="list">
            <li v-for="(item, index) in list" :key="item.follow_id">
              <div
                class="pro"
                :class="{
                  checked: item.selected,
                  invalid: item.del_flag == '1',
                }"
                @click="onClick"
                :data-i="index"
                :data-goodsid="item.goods_id"
              >
                <img :src="item.goods_info_img_id" />
                <p>{{ item.follow_price }}</p>
                <strong>{{ item.goods_info_name }}</strong>
              </div>
            </li>
          </ul>
        </div>

        <pager
          :now="pageNow"
          :show="pageSize"
          :total="pageTotal"
          :rows="rows"
          @onPage="onPage"
          @onChangeRows="onChangeRows"
        ></pager>
      </div>
    </div>

    <tmpl_footer />
  </div>
</template>
<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import sidebar from "./sidebar.vue";
import pager from "./pager.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: false,
      selectedAll: false,
      list: [],

      rows: 0,
      pageSize: 10,
      pageNow: 1,
      pageTotal: 1,
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
    sidebar,
    pager,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  watch: {},

  created: function () {
    if (!this.username)
      this.$router.push({
        path: "/signin",
        query: { to: encodeURI("/center/order") },
      });

    api.all([this.loadList()]).then(
      api.spread(() => {
        this.loading = false;
      })
    );
  },

  methods: {
    loadList: function () {
      return api
        .post(apis.center.favors, {
          orderStatus: "",
          pageSize: this.pageSize,
          pageNo: this.pageNow,
        })
        .then((res) => {
          this.list = res.records.map((item) => {
            item.selected = false;
            return item;
          });
          this.rows = res.total;
          this.pageNow = res.current;
          this.pageTotal = res.pages;
          this.selectedAll = false;
        });
    },

    onClick(e) {
      var target = e.target.nodeName,
        i = e.currentTarget.dataset.i,
        goodsid = e.currentTarget.dataset.goodsid;
      if (target === "DIV") {
        this.list[i].selected = !this.list[i].selected;
      } else {
        this.$router.push({ path: "/goods/view/" + goodsid });
      }
    },

    checkAll() {
      this.selectedAll = !this.selectedAll;
      this.list.map((item) => {
        item.selected = this.selectedAll;
        return item;
      });
    },

    cancelFavor() {
      let ids = [];
      this.list.map((item) => {
        if (item.selected) ids.push(item.follow_id);
      });

      if (!ids.length) return;

      return api
        .post(apis.center.removeFavor, {
          followIds: ids,
        })
        .then(() => {
          this.loadList();
        });
    },

    onPage(e) {
      this.pageNow = e.page;
      this.loadList();
    },

    onChangeRows(e) {
      this.pageSize = e.rows;
      this.loadList();
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./favor.less");
</style>
