<template>
  <div class="pager" v-if="props.total > 0">
    <span>共{{ props.rows }}条</span>
    <div class="picker">
      <a class="">{{ props.show }}条/页</a>
      <ul>
        <li data-rows="10" @click="onChangeRows">10条/页</li>
        <li data-rows="20" @click="onChangeRows">20条/页</li>
        <li data-rows="100" @click="onChangeRows">100条/页</li>
      </ul>
    </div>
    <a
      class="prev"
      :class="{ dis: props.now <= 1 }"
      :data-page="props.now - 1"
      @click="onPage"
    ></a>
    <span class="num">{{ props.now }}/{{ props.total }}</span>
    <a
      class="next"
      :class="{ dis: props.now >= props.total }"
      :data-page="props.now + 1"
      @click="onPage"
    ></a>
    <input type="text" v-model="state.go" />
    <a class="go" @click="gotoPage">GO</a>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";

export default {
  props: ["now", "total", "rows", "show"],

  setup(props, { emit }) {
    const state = reactive({
      go: "",
      pickRows: false,
    });

    onMounted(() => {});

    const onPage = (e) => {
      const page = e.currentTarget.dataset.page;
      if (!page) return;
      if (page <= 0 || page > props.total) return;

      emit("onPage", {
        page: page,
      });
    };

    const onChangeRows = (e) => {
      const rows = e.currentTarget.dataset.rows;
      if (!rows || rows === props.rows) return;

      emit("onChangeRows", {
        rows: rows,
      });
    };

    const gotoPage = () => {
      if (!state.go || isNaN(state.go)) return;
      const page = parseInt(state.go, 10);

      emit("onPage", {
        page: page,
      });

      state.go = "";
    };

    return { state, props, gotoPage, onPage, onChangeRows };
  },
};
</script>

<style lang="less">
.pager {
  height: 24px;
  color: #444444;
  text-align: right;
  font-size: 0;

  > * {
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    font-size: 12px;
  }

  span {
    line-height: 24px;
    padding: 0 8px;
  }

  .num,
  .picker,
  .next,
  .prev,
  input,
  .go {
    height: 24px;
    background: #ffffff;
    border: 1px solid #dddddd;
    margin-right: 4px;
    line-height: 22px;
    border-radius: 2px;
  }

  .picker {
    position: relative;
    padding: 0 20px 0 8px;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 12px;
      height: 22px;
      right: 4px;
      top: 0;
      background: url("data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCI+PHBhdGggZD0iTTgzNy4xMiAzMzQuNzJhNDcuMzYgNDcuMzYgMCAwIDAtNjcuMiAwbC0yNTYgMjU2LTI1Ni0yNTZhNDcuMzYgNDcuMzYgMCAwIDAtNjQgMCA0Ni43MiA0Ni43MiAwIDAgMCAwIDY0bDI4OC42NCAyODguNjRBNDQuOCA0NC44IDAgMCAwIDUxMiA3MDRhNDcuMzYgNDcuMzYgMCAwIDAgMzMuOTItMTQuMDhsMjg4LjY0LTI4OC42NGE0Ni43MiA0Ni43MiAwIDAgMCAyLjU2LTY2LjU2eiIgZmlsbD0iIzQ0NCIvPjwvc3ZnPg==")
        no-repeat center;
      background-size: contain;
    }

    ul {
      display: none;
      position: absolute;
      left: -1px;
      right: 0;
      top: 24px;
      border: 1px solid #dddddd;
      background: #fff;
      border-radius: 2px;

      li {
        padding: 3px 8px;
        cursor: pointer;
      }

      li:hover {
        background: #eee;
      }
    }

    &:hover ul {
      display: block;
    }
  }

  .prev,
  .next {
    width: 24px;
    text-align: center;
    cursor: pointer;
  }

  .prev::before,
  .next::before {
    content: "";
    display: inline-block;
    vertical-align: top;
    height: 22px;
    width: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .dis {
    cursor: default;
  }

  .dis::before {
    opacity: 0.3;
  }

  .prev::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCI+PHBhdGggZD0iTTY4OS45MiAxOTJhNDcuMzYgNDcuMzYgMCAwIDEgMCA2NGwtMjU2IDI1NiAyNTYgMjU2YTQ3LjM2IDQ3LjM2IDAgMCAxLTY0IDY0TDMzNC4wOCA1NDUuOTJBNTAuNTYgNTAuNTYgMCAwIDEgMzIwIDUxMmE0Ny4zNiA0Ny4zNiAwIDAgMSAxNC4wOC0zMy4yOEw2MjIuNzIgMTkyYTQ3LjM2IDQ3LjM2IDAgMCAxIDY3LjIgMHoiIGZpbGw9IiM0NDQiLz48L3N2Zz4=");
  }

  .next::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCI+PHBhdGggZD0iTTMzNC4wOCAxOTJhNDcuMzYgNDcuMzYgMCAwIDAgMCA2NGwyNTYgMjU2LTI1NiAyNTZhNDcuMzYgNDcuMzYgMCAxIDAgNjQgNjRsMjg4LjY0LTI4OC42NEE1MC41NiA1MC41NiAwIDAgMCA3MDQgNTEyYTQ3LjM2IDQ3LjM2IDAgMCAwLTE0LjA4LTMzLjI4TDQwMS4yOCAxOTJhNDcuMzYgNDcuMzYgMCAwIDAtNjcuMiAweiIgZmlsbD0iIzQ0NCIvPjwvc3ZnPg==");
  }

  input {
    width: 37px;
    margin-right: 0;
    border-right: 0;
  }

  .go {
    width: 35px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
